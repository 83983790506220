import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent } from '@cf/shared/ui/icons';
import { ConnectionService } from '@cf/temp/services';

@Component({
  selector: 'cf-microsoft-button',
  imports: [IconComponent],
  templateUrl: './microsoft-button.component.html',
  styleUrl: './microsoft-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MicrosoftButtonComponent {
  @Input() isCalendar = true;

  @Input() redirectTarget: '_self' | '_blank' = '_self';

  constructor(private readonly connectionService: ConnectionService) {}

  connect() {
    if (this.isCalendar) {
      this.connectionService.microsoftRedirect(this.redirectTarget);
    }
  }
}
