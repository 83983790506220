import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BackendService,
  BaseApiService,
  BaseStatusResponse,
  RequestFacadeModel,
  RequestModel,
  RequestType,
} from '@cf/core';
import { ModalService } from '@cf/shared/dialog';

import { Actions } from '@ngneat/effects-ng';
import { TranslateService } from '@tolgee/ngx';
import { CookieService } from 'ngx-cookie-service';
import { catchError, finalize, from, mergeMap, of } from 'rxjs';
import { CalendarStoreService, loadOrganizations } from '..';
import { GoogleCalendarDto } from '../auth/dtos/google-calendar.dto';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService extends BaseApiService {
  // eslint-disable-next-line no-untranslated-string/no-untranslated-string
  public broadcastChannel = new BroadcastChannel('CALENDAR_HANDLER');
  private readonly modalService = inject(ModalService);

  #actions = inject(Actions);
  #calendarStoreService = inject(CalendarStoreService);
  translateService = inject(TranslateService);

  constructor(
    private readonly router: Router,
    private readonly cookieService: CookieService,
    backendService: BackendService,
  ) {
    super(backendService, 'connections');
  }

  zoom(code: string) {
    const request: RequestModel<any> = new RequestModel<any>({
      url: this.getFullUrl('zoom'),
      requestBody: { code },
      successMessage: {
        message: this.translateService.instant('settings.connections.zoom_success'),
        title: this.translateService.instant('meeting_tools.zoom'),
      },
    });
    const requestFacade: RequestFacadeModel<GoogleCalendarDto> = new RequestFacadeModel<any>({
      requestType: RequestType.post,
      request,
    });
    if (this.#calendarStoreService.calendars.length) {
      if (requestFacade.request.requestBody) {
        requestFacade.request.requestBody.set_meeting_tool_to_locations = false;
      }
      return this.send<BaseStatusResponse, any>(requestFacade).pipe(
        finalize(() => {
          this.#actions.dispatch(loadOrganizations());
          void this.router.navigate(['/integrations/manage-tools/ZOOM'], { replaceUrl: true });
        }),
        catchError((err) => {
          void this.router.navigate(['/settings/meeting-tools']);
          return of(err);
        }),
      );
    }

    return from(
      this.modalService.openConfirmation(
        this.translateService.instant('calendars.apply_zoom_calendar_title'),
        this.translateService.instant('calendars.apply_zoom_calendar_text'),
        this.translateService.instant('calendars.save_and_apply'),
        this.translateService.instant('calendars.set_up_later'),
        '',
      ).result,
    ).pipe(
      mergeMap((result) => {
        if (requestFacade.request.requestBody) {
          requestFacade.request.requestBody.set_meeting_tool_to_locations = result;
        }

        return this.send<BaseStatusResponse, any>(requestFacade);
      }),
      finalize(() => {
        this.#actions.dispatch(loadOrganizations());
        void this.router.navigate(['/integrations/manage-tools/ZOOM'], { replaceUrl: true });
      }),
      catchError((err) => {
        void this.router.navigate(['/settings/meeting-tools']);
        return of(err);
      }),
    );
  }

  microsoftRedirect(target: '_self' | '_blank' = '_self') {
    const request: RequestModel<any> = new RequestModel<any>({
      url: this.getFullUrl('microsoft'),
    });
    const requestFacade: RequestFacadeModel<any> = new RequestFacadeModel<any>({
      requestType: RequestType.get,
      request,
    });
    this.cookieService.delete('flow_key', '/');
    this.send<any, any>(requestFacade).subscribe((res) => {
      this.cookieService.set('flow_key', res.flow_key, new Date().setMinutes(30), '/');
      window.open(res.auth_uri, target);
    });
  }

  microsoftConnect(dto: any) {
    const request: RequestModel<any> = new RequestModel<any>({
      url: this.getFullUrl('microsoft'),
      requestBody: dto,
      skipNotify: true,
      successMessage: {
        message: this.translateService.instant('settings.connections.microsoft_success'),
        title: this.translateService.instant('meeting_tools.microsoft_title'),
      },
    });
    const requestFacade: RequestFacadeModel<any> = new RequestFacadeModel<any>({
      requestType: RequestType.post,
      request,
    });
    return this.send<any, any>(requestFacade);
  }

  appleConnect(dto: { email: string; password: string }) {
    const request: RequestModel<any> = new RequestModel<any>({
      url: this.getFullUrl('apple'),
      requestBody: dto,
      skipNotify: true,
      successMessage: {
        message: this.translateService.instant('settings.connections.apple_success'),
        title: this.translateService.instant('meeting_tools.apple_title'),
      },
    });
    const requestFacade = new RequestFacadeModel<any>({
      requestType: RequestType.post,
      request,
    });
    return this.send<any, any>(requestFacade);
  }
}
